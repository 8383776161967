export const APP_VERSION = {
 version: 'v4.38.3',
 data: '13/08/2024',
 tipo: 'sustentação'
};

export const MSG_SUCCES = 'Operação realizada com sucesso.';
export const MSG_DELETE = 'Você tem certeza que deseja excluir este registro?';
export const MSG_FALIELD = 'Falha na operação.';
export const MSG_QUESTION_DELETE = 'Deseja Excluir';
export const MSG_QUESTION_INACTIVATE = 'Deseja Inativar';
export const MSG_QUESTION_ACTIVATE = 'Deseja Ativar';
export const MSG_INACTIVATE_SUCCESS = 'Registro inativado com sucesso!';
export const MSG_ACTIVATE_SUCCESS = 'Registro ativado com sucesso!';
export const MSG_CREATE_SUCCCESS = 'Registro cadastrado com sucesso!';
export const MSG_ADD_SUCCCESS = 'Registro adicionado com sucesso!';
export const MSG_ADD_EDIT_SUCCCESS = 'Registro adicionado/editado com sucesso!';
export const MSG_UPDATE_SUCCCESS = 'Registro atualizado com sucesso!';
export const MSG_SALA_NOT_FOUND = 'Nenhuma caracterização e infraestrutura cadastrada';
export const MSG_EMAIL_GESTOR_ESCOLAR = 'Deseja enviar e-mail de primeiro acesso para todos os usuários com perfil GESTOR ESCOLAR ?';
export const MSG_EMAIL_GRE = 'Deseja enviar e-mail de primeiro acesso para todos os usuários com perfil GERENTE REGIONAL DE ENSINO ?';
export const MSG_EMAIL_SEECT = 'Deseja enviar e-mail de primeiro acesso para todos os usuários com perfil SEECT ?';
export const MSG_EMAIL_ADMINISTRADOR = 'Deseja enviar e-mail de primeiro acesso para todos os usuários com perfil ADMINISTRADOR ?';
export const MSG_ENVIO_EMAIL_POR_PERFIL = 'Operação realizada com sucesso. Os e-mails estão sendo enviados!';
export const MSG_ANALISE_ALUNO = 'Deseja realmente finalizar a análise desse estudante?';
export const MSG_ALERTA_SOLICITACAO_AJUSTE = 'No espaço para observação, indique apenas o(s) item(s) que o estudante precisa ajustar para efetivar sua matrícula, relembrando que os itens que já estão corretos não precisam de alteração.';
export const MSG_QUESTION_REMOVE = 'Confirma a remoção do(a) aluno(a) ';
export const MSG_QUESTION_FINALIZAR_ENTURMACAO = 'Confirma a finalização do processo de enturmação da escola ';
export const MSG_FAIL_PDF = 'Por gentileza anexe um PDF';
export const MSG_SUCESS_PROGRESSION = 'Aluno(a) progredido(a) com sucesso!';
export const MSG_SUCESS_ALTER_PROGRESSION = 'Alteração na progressão do Aluno(a) realizada com sucesso!';
export const MSG_SUCCESS_REGISTRO_CONSELHO = 'Conselho de Classe Registrado com sucesso!';
export const MSG_SUCCESS_UPDATE_REGISTRO_CONSELHO = 'Conselho de Classe Atualizado com sucesso!';
export const MSG_FAIL_PDF_JPG = 'Por gentileza anexe um arquivo PDF.';
export const MSG_CONFIRMAR_CAMPOS_OBRIGATORIOS = 'Para confirmar se faz necessário que todos os campos estejam preenchidos!'
export const MSG_DELETE_ITEM = 'Tem certeza que deseja realmente excluir o item selecionado?';
export const MSG_INACTIVE_REGISTRATIONS = 'Esta matrícula está inativa, não permitindo inserções ou edições de faltas justificadas.'
export const MSG_CAMPOS_OBRIGATORIOS = 'Todos os campos obrigatórios precisam ser preenchidos corretamente.';
export const MSG_VALIDATION_FINAL_DATE = 'A data fim não pode ser anterior à data início, favor corrigir para prosseguir com a justificativa.'
export const MSG_VALIDATION_START_DATE = 'A data início não pode ser maior que a data fim, favor corrigir para prosseguir com a justificativa.'
export const MSG_TOOLTIP_JUSTIFICADA= ' Caso deseje justificar apenas um dia, informe a mesma data nos campos de Data Início e Data Fim.'
export const MSG_JUSTIFY_SUCESS='Justificativa realizada com sucesso!'
export const MSG_JUSTIFY_SUCESS_ATT='Justificativa atualizada com sucesso!'
export const MSG_UPDATE_TAB_FORMACAO_ACADEMICA_CONCLUIR_ENVIAR_SUCCESS = 'Registro finalizado e enviado com sucesso!';
export const MSG_UPDATE_TAB_FORMACAO_ACADEMICA_SUCCESS = 'Formação acadêmica salva com sucesso. Se todos os seus dados estão preenchidos corretamente, clique no botão "Concluir e Enviar" para a GRE validar seus dados.';
export const MSG_CONFIRMACAO_ENVIO = 'Toda a documentação será analisada pela sua GRE, <br> sendo <strong> APROVADA </strong> ou <strong> REPROVADA </strong>. <br><br> Deseja Prosseguir?'
export const MSG_COMPARACAO_POS_GRADUCAO_COM_ENSINO_SUPERIOR = 'O ano de conclusão da pós-graduação não pode ser anterior ao ano mais recente de conclusão da formação superior.';
export const MSG_CURSO_ESPECIFICO_EXISTENTE = 'Este Curso Específico já foi adicionado. Por favor, verifique o campo selecionado.';
export const MSG_COMPOENENTE_CURRICULAR_EXISTENTE = 'Este Componente Curricular já foi adicionado. Por favor, verifique o campo selecionado.';
export const MSG_EXPORTACAO_QUADRO_SEM_CONCLUIDOS = 'A exportação não pode ser concluída, pois não há nenhum quadro de horário com a situação "Concluído".';
export const MSG_MODAL_CONFIRMACAO_NOVA_VERSAO = 'Ao atualizar um quadro de horário já concluído é criado uma nova versão e os sistemas que possuí alguma dependência passar a utilizar essa nova versão!<br> Tem certeza que deseja realizar a atualização?';
export const MSG_MODAL_QUADRO_COMPONENTE_AULAS_COMPLETAS = 'Não há mais aula para serem atribuídas para esse componente, por favor selecione outro componente ou modifique a configuração atual.';